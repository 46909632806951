import React from "react"
import Box from "./box"
import PropTypes from "prop-types"

const Clearfix = ({ children }) => {
  return (
    <Box
      sx={{
        ":after": {
          content: `""`,
          display: "block",
          clear: "both",
        },
      }}
    >
      {children}
    </Box>
  )
}

Clearfix.propTypes = {
  children: PropTypes.node,
}

export default Clearfix
